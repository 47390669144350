import React, { useState, useEffect } from 'react';
import { FaInstagram, FaLinkedin,  } from 'react-icons/fa'; // Import Font Awesome icons
import Phone from './phone.png';
import Logo from './derimate-logo.svg';

const App = () => {
  const [email, setEmail] = useState('');
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    const targetDate = new Date('2024-12-31').getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown({
        days: days.toString().padStart(2, '0'),
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0')
      });

      if (distance < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      backgroundColor: '#fff',
      padding: '2rem',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <div style={{
        flex: 1,
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1
      }}>
        {/* Left Content */}
        <div style={{ flex: 1 }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            marginBottom: '2rem'
          }}>
            <img src={Logo} alt="Lightbulb" style={{ width: '100%', height: '100%' }} />
          </div>

          <div style={{ marginBottom: '2rem' }}>
            <h1 style={{ fontSize: '48px', fontWeight: 'bold', margin: 0 }}>Under</h1>
            <h2 style={{ fontSize: '48px', fontWeight: 'bold', margin: 0 }}>construction</h2>
          </div>

          <p style={{
            color: '#666',
            marginBottom: '2rem',
            maxWidth: '600px'
          }}>
            Making Futures & Options Trading Effortless
          </p>

          <div style={{
            display: 'flex',
            gap: '2rem',
            marginBottom: '2rem'
          }}>
            {Object.entries(countdown).map(([unit, value]) => (
              <div key={unit} style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '48px', fontWeight: 'bold', color: '#1a1a1a' }}>{value}</div>
                <div style={{ color: '#666', textTransform: 'capitalize' }}>{unit}</div>
              </div>
            ))}
          </div>

          {/* Social Media Icons */}
          <div style={{ display: 'flex', gap: '1rem', marginTop: '2rem' }}>
            <a href="https://www.instagram.com/derimate_official" target="_blank" rel="noopener noreferrer" style={{ color: '#1DA1F2' }}>
              <FaInstagram size={30} />
            </a>
            <a href="https://www.linkedin.com/company/derimateofficial" target="_blank" rel="noopener noreferrer" style={{ color: '#0077B5' }}>
              <FaLinkedin size={30} />
            </a>
          </div>
        </div>

        {/* Right Content */}
        <div style={{ flex: 1 }}>
          <div style={{
            position: 'relative',
            left: '-50px',
            width: '100%',
            maxWidth: '400px',
            margin: '0 auto'
          }}>
            <img src={Phone} alt="Phone mockup" style={{ width: '150%', height: 'auto' }} />
          </div>
        </div>
      </div>

      {/* Background Decorations */}
      <div style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: '30%',
        background: '#4285f4',
        borderRadius: '50% 0 0 50%',
        opacity: 0.1
      }} />
      <div style={{
        position: 'absolute',
        bottom: '10%',
        left: '5%',
        width: '100px',
        height: '100px',
        background: '#fbbc05',
        borderRadius: '50%',
        opacity: 0.1
      }} />
    </div>
  );
};

export default App;
